import React, { useMemo } from 'react';
import { oneDark } from '@codemirror/theme-one-dark';
import { cpp } from '@codemirror/lang-cpp';
import { basicSetup } from '@codemirror/basic-setup';
import CodeMirror from '@uiw/react-codemirror';
import './editor.css';

const Editor = props => {
    const { code, onUpdate } = props;
   
    const extensions = useMemo(
      () => [basicSetup, cpp()],
      [],
    )

    const handleValue = code => {
      onUpdate(code);
    }
    
    return (
      <CodeMirror
        className="surfboard-codemirror"
        value={code}
        height="500px"
        theme={oneDark}
        onChange={(value, viewUpdate) => {
            handleValue(value);
        }}
        extensions={extensions}
      />
    )
    
 
}

export default Editor;

