import React, { useEffect, useState, useCallback } from 'react';
import { Button, SIZE, SHAPE, KIND } from 'baseui/button';
import { Input } from 'baseui/input';
import { useStyletron } from 'baseui';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import Editor from '../Editor';
import Configuration from "../Configuration";
import { getInfo } from '../../utils/clientApi';
import { convertBoardApi2Client } from '../../utils/common';
import { flash } from "../../utils/flash";
import { ReactComponent as Logo } from '../../assets/surfboard.svg';
import './surfboard.css';
import 'xterm/css/xterm.css';

import _ from 'lodash';

const term = new Terminal();
const fitAddon = new FitAddon();
term.loadAddon(fitAddon);

const Surfboard = props => {
    const { appId, serial } = props;

    const [css] = useStyletron();

    const [selectBoard, setSelectBoard] = useState(null);
    const [boards, setBoards] = useState([]);
    const [libs, setLibs] = useState([]);
    const [files, setFiles] = useState([]);
    const [primaryFile, setPrimaryFile] = useState("");
    const [projectName, setProjectName] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [baud, setBaud] = useState(0);
    const [isConnected, setIsConnected] = useState(false);
    const [isAppLoading, setIsAppLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFlashed, setIsFlashed] = useState(false);
    const [termText, setTermText] = useState("");

    const termRef = useCallback(async node => {
        if (node != null) {
            console.log("isConnected: ", isConnected);
            await handleTerminal(node);
        }
    }, []);

    const handleTerminal = async node => {
        term.open(node);
        fitAddon.fit();
        
        await serial.on('data', function(data) {
            term.write(data.toString().replace(/\r\n/g, '\n').replace(/\n/g, '\r\n'));
        });
    }

    useEffect(() => {
        let isSubscribed = true;

        const splashTimer = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1500);
            })
        }

        const init = async () => {
            const info = await getInfo(appId);

            if (isSubscribed) {
                setFiles(info.files);
                setLibs(info.libs);
                setBoards(info.compatibleBoards);
                setIsConnected(serial.connected);
                setBaud(serial.baud);

                for (const file of info.files) {
                    if (file.primary === true) {
                        setProjectName(`${file.name.split(".")[0]}`);
                        setPrimaryFile(file.content);
                    }
                }
            }

            await splashTimer();
            setIsAppLoading(false);
        };

        init();
        return () => isSubscribed = false;

    }, [appId]);

    const LoadingScreen = () => (
        <div className="surfboard-loading">
            <h1 className="loading-blink">_</h1>
        </div>
    )

    const handleSelect = index => {
        const selectedBoard = boards[index];
        const flashBoard = convertBoardApi2Client(selectedBoard);
        setSelectBoard(flashBoard);
    }

    const parseFlash = () => {
        
        const flashInfo = {};
        flashInfo.board = selectBoard;
        flashInfo.libs = libs;
        flashInfo.files = files.map(file => {
            if (file.primary) {
                return {
                    content: primaryFile,
                    name: `${projectName}/${file.name}`
                }
            } else {
                return {
                    content: file.content,
                    name: `${projectName}/${file.name}`
                }
            }});
        flashInfo.flags = {
            verbose: false,
            preferLocal: false
        };

        return flashInfo;
    }

    const handleFlash = async () => {
        setErrorMsg("");
        setIsLoading(true);

        const flashInfo = parseFlash();

        if (isFlashed === false) {
            try {
                const log = await flash(serial, flashInfo);
                console.log('log: ', log);
                setIsFlashed(true);
                setIsLoading(false);

            } catch (err) {
                setIsLoading(false);
                setErrorMsg(err.message);
                return;
            }
        }
    }

    const handleConnect = async () => {
        setIsLoading(true);

        if (!isConnected) {
            try {
                await serial.requestDevice();
                setIsConnected(true);
                await termRef();
            } catch (err) { 
                setErrorMsg(err.message);
                console.log('requestDevice rejected');
                setIsLoading(false);
                return;
            }
        }
        
        setErrorMsg("");
        setIsLoading(false);
    }

    const handleUpdate = code => {
        setPrimaryFile(code);
    }

    const handleBaud = value => {
        setBaud(value);
        serial.setBaud(value);
    }

    const handleWrite = async () => {
        const data = `${termText}'\n'`;
        const buff = Buffer.from(data);
        await serial.serial.write(buff, function(error) {
            if (error) {
                console.log(error)
            }
        });
    }

    if (isAppLoading) {
        return (
            <LoadingScreen />
        )
    } else {
        return (
            <div className="surfboard-embed">
                <div className="surfboard-container" id='surfboard-container'>
                    <Editor code={primaryFile} onUpdate={handleUpdate} />
                    {files ? (
                        <div className="surfboard-interactive">
                            <div className="surfboard-connect-container">
                                <Configuration boards={boards} onSelect={handleSelect} disabled={!isConnected} />
                                <div className="surfboard-flash">
                                    {errorMsg 
                                    ? (
                                        <p className="surfboard-error-msg">{errorMsg}</p>
                                    )
                                    : null       
                                    }
                                    {isConnected
                                        ? (
                                            <Button 
                                                size={SIZE.compact}
                                                shape={SHAPE.pill}
                                                onClick={handleFlash}
                                                isLoading={isLoading}
                                                className={css({
                                                    fontWeight: 600,
                                                    ":enabled:hover": {backgroundColor: "#1F70E9"},
                                                })}
                                                disabled={!selectBoard}
                                            >
                                                Flash
                                            </Button>
                                        )
                                        : (
                                            <Button
                                                size={SIZE.compact}
                                                shape={SHAPE.pill}
                                                kind={KIND.secondary}
                                                onClick={handleConnect}
                                                isLoading={isLoading}
                                            >
                                                Connect
                                            </Button>
                                        )}
                                </div>
                            </div>
                            {isConnected ? (
                            <div className="surfboard-monitor">
                                <div className="surfboard-send">
                                    <Input
                                        size={SIZE.mini}
                                        startEnhancer="baud"
                                        value={baud}
                                        onChange={e => handleBaud(e.target.value)}
                                        overrides={{
                                            Root: {
                                            style: {
                                                width: '40%'
                                            },
                                            },
                                        }}
                                    />
                                    <Input 
                                        size={SIZE.mini} 
                                        value={termText}
                                        onChange={e => setTermText(e.target.value)}
                                    />
                                    <Button 
                                        className={css({
                                            fontWeight: 600,
                                        })}
                                        kind={KIND.tertiary}
                                        size={SIZE.mini} 
                                        onClick={handleWrite}
                                        overrides={{
                                            BaseButton: {
                                                style: ({ $theme }) => ({
                                                    color: "#FFFFFF",
                                                    backgroundColor: '#1F70E9',
                                                    ":enabled:hover": {
                                                        color: "#1F70E9",
                                                        backgroundColor: "#FFFFFF"
                                                    },
                                                })
                                            }
                                        }}
                                    >
                                        Send
                                    </Button>
                                </div>
                                <div className="surfboard-terminal" ref={termRef} id="surfboard-terminal" />          
                            </div>
                            ) : null}
                        </div>
                    ): null}
                </div>
                <footer className="surfboard-app-footer">
                    <a className="surfboard-footer-link" href="https://www.sfbd.is" target="_blank" rel="noopener noreferrer">
                        <h5 className="surfboard-footer-text">With ❤ from <Logo className="surfboard-wordmark" /></h5>
                    </a>
                </footer>
            </div>
        )
    }
}

export default Surfboard;