import React from 'react';
import Surfboard from './components/Surfboard';
import SurfSerial from './utils/SerialImpl';
import "./App.css";

const serial = new SurfSerial();

function App(props) {
  return (
      <div className="surfboard-app">
        <Surfboard appId={props.appid} serial={serial}/>
        
      </div>
  );
}

export default App;
